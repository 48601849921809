ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.pricing-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;

  .pricing-toggle {
    margin-bottom: 2rem;
    position: relative;
    display: flex;
    justify-content: center; // Center the ToggleButtonGroup horizontally

    .MuiToggleButtonGroup-root {
      display: inline-flex; // Ensure the ToggleButtonGroup is inline
    }

    .MuiToggleButton-root {
      position: relative;
      padding-right: 3rem; // Increase padding to accommodate the discount banner
    }

    .discount {
      position: absolute;
      top: -10px;
      right: -10px;
      background-color: #ff6b6b;
      color: white;
      padding: 2px 5px;
      font-size: 0.75rem;
      border-radius: 3px;
      transform: rotate(15deg);
    }
  }

  .pricing-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
}

.pricing-card {
  flex: 1;
  min-width: 250px;
  max-width: 350px;
  position: relative;
  overflow: visible;
  margin: 10px;

  &--popular {
    transform: scale(1.05);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }

  &__banner {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #4caf50;
    color: white;
    padding: 4px 12px;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .MuiCardContent-root {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;

    > * {
      margin: 10px 0;
    }
  }

  .MuiTypography-root,
  li {
    text-align: center;
  }
}

// Add this to your existing styles
.MuiSlider-root {
  color: #3f51b5;
  margin-bottom: 40px;
}

.MuiSlider-valueLabel {
  background-color: #3f51b5;
}
