.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &__text {
    font-size: 18px;
    font-weight: bold;
  }

  &__dots {
    display: inline-block;
  }

  &__dot {
    opacity: 0;
    animation: dot-animation 1.4s infinite;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes dot-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
