.alias-view {
  &__maxed-out {
    color: #ff4c4c;
    font-size: 0.8em;
    margin-bottom: 10px;
    text-align: right;
  }
}

.stats {
  &.global {
    position: absolute;
    top: 15px;
  }

  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  p {
    margin: 0;
    margin-right: 20px;
    font-size: 0.8em;
    color: #666;

    span {
      font-weight: bold;
      color: #333;
    }
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.email-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  margin: 0 0 10px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  & > * {
    margin: 0 5px;
  }

  &__content {
    flex: 1;
  }

  &__alias {
    display: inline-block;
    font-size: 0.9em;
    font-weight: bold;
    margin-bottom: 8px;
    cursor: pointer;
    padding-bottom: 2px;
    border-bottom: 1px dashed transparent;
    transition: all 0.2s ease-in-out;
    svg {
      margin-left: 5px;
    }

    &--active {
      &:hover {
        border-bottom: 1px dashed #333;
      }

      &:active {
        transform: translateY(1px);
        opacity: 0.4;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .stats {
    margin: 0;
    font-size: 0.8em;
  }
}

.delete-alias {
  margin: 0;
  padding: 0 14px;
  line-height: 1.2em;
}

.create-alias {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  justify-content: center;

  &__suffix {
    background-color: #222;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: #fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  input {
    height: 50px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    outline: none;
    margin: 0;
    border-radius: 0px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-size: 0.9em;
    color: rgba(0, 0, 0, 0.9);
    transition: box-shadow 0.2s;
    display: flex;
    justify-content: center;

    &:focus {
      box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
    }

    &::placeholder {
      font-family: 'Josefin Slab';
      font-size: 0.8em;
      color: rgba(0, 0, 0, 0.3);
    }
  }
}

.empty-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 25px;
  border-radius: 10px;
  border: solid 1px #ccc;

  &__title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__subtitle {
    font-size: 0.9em;
    line-height: 1.5em;
    color: #666;
    margin: 20px 0;
  }
}
