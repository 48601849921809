@import '../../_globals.scss';

#auth {
  background-color: black;
  height: 100%;
  color: white;

  a {
    color: $primary-accent;
    text-decoration: none;
    font-weight: 500;
    transition: opacity 0.2s ease-in;

    &:hover {
      opacity: 0.8;
      text-decoration: none;
      font-weight: 500;
    }
  }

  #content {
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
    position: relative;

    .account-form {
      padding-left: 50px;

      form {
        display: flex;
        flex-direction: column;
        max-width: 400px;
      }

      .input {
        height: 40px;
        margin-bottom: 20px;

        input {
          border-radius: 14px;

          &:focus {
            border-color: $primary-accent;
            background-color: rgba(255, 255, 255, 0.1);
            box-shadow: 0 0 15px -4px #fff509;
          }
        }
      }
    }
  }

  label {
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
      margin-right: 7px;
    }
  }

  .error-container {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;

    &__error {
      background-color: red;
      border-radius: 14px;
      padding: 14px;
      font-weight: 500;
      font-size: 0.9em;
    }
  }

  .button {
    .login-button-text {
      height: 100%;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
  }
}

#auth p.freemessage {
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
  font-size: 0.8em;
  color: rgba(255, 255, 255, 0.5);
}

#auth h1 {
  margin: 0;
}

@media (max-width: 465px) {
  #auth {
    #content {
      .account-form {
        padding-left: 0;
      }
    }
  }
}
