@import '../../_globals.scss';

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;

  svg {
    .shape {
      stroke: transparent;
    }
    .loader {
      stroke: $primary-accent;
      stroke-dasharray: 40 60;
      stroke-width: 4;
      animation: loading 2s linear infinite;
    }
  }
}

@keyframes loading {
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 100;
  }
}
