@import '../../globals.scss';

.account-verification {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__confirmation {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__disclaimer {
      font-size: 0.8em;
    }

    &__message {
      font-size: 0.9em;
      padding: 16px;
      background-color: $primary-success;
      border-radius: 8px;
    }
  }

  &__input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    border-radius: 8px;
    overflow: hidden;
    border: 1px solid $grey;

    &.focused {
      border: 1px solid $primary-accent;
    }

    .input {
      width: 180px;

      input {
        border: 0px;
        border-radius: 0px;
      }
    }

    .button {
      height: 50px;
      border: 0px;
      border-radius: 0px;
    }
  }
}
