#profile {
  display: flex;
  flex-direction: column;

  .label {
    margin-left: 5px;
  }

  .input {
    margin-top: 5px;
    max-width: 300px;
  }

  .profile-actions {
    margin: 10px 0 20px 0;
    justify-content: flex-start;

    .input {
      margin-top: 0px;
      color: white;
      border-right: 0;
      min-width: 250px;

      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &::placeholder {
        color: #ccc;
      }
    }

    .button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      height: 100%;
      margin-right: 5px;
    }
  }

  .button {
    align-self: flex-start;
  }
}
