.label {
  padding: 5px 8px;
  font-size: 0.8em;
  border-radius: 5px;
  display: inline-block;
  font-weight: bold;

  &--primary {
    background-color: #e6f7ff;
    color: #1890ff;
  }

  &--info {
    background-color: #e6f7ff;
    color: #1890ff;
  }

  &--success {
    background-color: #f6ffed;
    color: #52c41a;
  }

  &--warning {
    background-color: #fff7e6;
    color: #faad14;
  }

  &--error {
    background-color: #fff1f0;
    color: #f5222d;
  }
}
