#drawer,
#list {
  display: flex;
  flex-direction: column;
  position: relative;
}

#drawer {
  width: 150px;

  .drawer__content {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: inherit;
    height: 100vh;
    box-sizing: border-box;
    padding: 10px 0px 20px 10px;
    #logo {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 10px 0;

      p {
        position: absolute;
        top: 14px;
        right: 40px;
        color: #fff509;
        font-size: 0.9em;
        transform: rotate(-10deg);
        font-weight: bold;
      }
    }
  }
}

#list {
  flex: 1;
  .item {
    margin-left: 10px;
    z-index: 1;
    color: white;

    transition: color 0.2s ease-in-out;
    &.selected {
      color: black;
    }

    background-color: transparent;
    height: 40px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    margin: 20px 0;
    text-decoration: none;
  }

  .button {
    margin-right: 10px;
  }

  .selector {
    position: absolute;
  }
}
