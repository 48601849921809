@import url('https://fonts.googleapis.com/css2?family=Langar:wght@100;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin%20Slab:wght@100;400&display=swap');

* {
  font-family:
    'Josefin Slab',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
#root {
  margin: 0;
  height: 100vh;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.row {
  display: flex;
  flex-direction: row;
}

.primary-text {
  color: #fff509;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 1);
  flex-direction: column;

  .button {
    margin-top: 20px;
  }

  &__content {
    text-align: center;
    color: black;
    font-weight: 400;
    padding: 20px;
    background-color: white;
    border-radius: 20px;

    svg {
      .shape {
        stroke: #333;
      }

      .bottom {
        fill: #333;
      }
    }

    p {
      margin-top: 20px;
      margin-bottom: 0;
    }

    &-error {
      color: red;
    }

    &-success {
      color: green;
    }
  }
}

.flex-row {
  display: flex;
  flex-direction: row;

  &.flex-wrap {
    flex-wrap: wrap;
  }
}
