.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  backdrop-filter: blur(2px);

  &.modal--opened {
    display: flex;
  }

  .modal__content {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    max-width: 500px;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    background-color: #1e1e1e;

    h1 {
      margin: 0;
      padding: 15px;
      color: white;
      font-size: 1.4em;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    padding: 10px;
  }

  &__input {
    height: 40px;
    border: 1px solid transparent;
    outline: 0;
    border-radius: 14px;
    padding: 0 10px;
    background-color: rgba(255, 255, 255, 0.2);
    transition: all 0.2s ease-in;
    color: white;

    &hover {
      height: 40px;
      border: 1px solid transparent;
      outline: 0;
      border-radius: 14px;
      padding: 0 10px;
      background-color: rgba(255, 255, 255, 0.15);
    }

    &focus {
      height: 40px;
      border: 1px solid #fff509;
      outline: 0;
      border-radius: 14px;
      padding: 0 10px;
      background-color: rgba(255, 255, 255, 0.1);
      box-shadow: 0 0 15px -4px #fff509;
    }
  }

  .modal__input--error {
    border: 1px solid #ff0000;
    box-shadow: 0 0 15px -4px #ff0000;
  }

  .modal__error {
    color: #ff0000;
    font-size: 0.8em;
    margin-top: 5px;
  }

  .modal__link {
    color: #fff509;
    text-decoration: none;
    font-weight: 500;
    transition: opacity 0.2s ease-in;
  }

  .modal__link:hover {
    opacity: 0.8;
    text-decoration: none;
    font-weight: 500;
  }

  .modal__link--disabled {
    color: #999;
    cursor: not-allowed;
  }

  .modal__link--disabled:hover {
    opacity: 1;
    text-decoration: none;
    font-weight: 500;
  }

  .modal__link--error {
    color: #ff0000;
  }

  .modal__link--error:hover {
    opacity: 0.8;
    text-decoration: none;
    font-weight: 500;
  }
}
