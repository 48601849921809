#page {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-color: #1e1e1e;
  color: white;
  padding: 10px;
  box-sizing: border-box;

  #content {
    flex: 1;
    position: relative;
    display: flex;
    border-radius: 20px;
    background-color: white;
    color: black;
    padding: 35px 20px 20px 20px;

    & > div {
      flex: 1;
    }
  }
}
