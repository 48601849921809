.button {
  border-radius: 14px;
  height: 40px;
  border: none;
  color: rgb(84, 84, 84);
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in;
  padding: 0 20px;
  overflow: hidden;

  &:active {
    transform: scale(0.98);
    box-shadow: none;
  }

  &__loadable-content {
    height: 100%;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;

    &:active {
      transform: none;
    }

    &:hover {
      box-shadow: none;
    }
  }

  &--light {
    background-color: #ccc;

    &:hover {
      box-shadow: 0 0 15px -2px #ccc;
    }
  }

  &--primary {
    background-color: #fff509;

    &:hover {
      box-shadow: 0 0 15px -2px #fff509;
    }
  }

  &--clear {
    background-color: transparent;

    &:hover {
      color: white;
    }
  }

  &--outline {
    background-color: transparent;

    &:hover {
      box-shadow: 0 0 15px -2px #ccc;
    }
  }

  &--danger {
    background-color: #ff0000;
    color: white;

    &:hover {
      box-shadow: 0 0 15px -2px #ff0000;
    }
  }
}
