@import '../../globals';

.input {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 50px;

  &__label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  input {
    border: 1px solid $grey-light;
    border-radius: 8px;
    font-size: 12px;
    flex: 1;
    outline: none;
    padding: 0 10px;
    transition: all 0.2s ease-in;

    &::placeholder {
      color: $grey-light;
    }

    &:hover {
      background-color: #fffdf2;
    }

    &:focus {
      border-color: $primary-accent;
    }
  }

  &.dark {
    input {
      border: 1px solid transparent;
      background-color: rgba(255, 255, 255, 0.2);
      color: white;

      &:hover {
        background-color: rgba(255, 255, 255, 0.15);
      }
    }
  }

  &--error {
    input {
      border: 1px solid rgba(255, 0, 0, 0.6) !important;
      box-shadow: 0 0 15px -4px #ff0000 !important;
    }
  }
}
