$primary-accent: #ffea00;
$primary-light: #fcda00;
$primary-dark: #eaba13;
$primary-success: #00b300;
$primary-danger: #ff4c4c;
$primary-danger-dark: #ee3737;
$primary-danger-light: #f65f5f;
$primary-black: #1f0322;
$grey: #6d656d;
$grey-light: #b4b4b4;
