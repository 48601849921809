.toggle {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 34px;
  background-color: #ccc;
  border-radius: 34px;
  transition: background-color 0.2s;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    transition: left 0.2s;
  }

  &.is-active {
    background-color: #fff509;

    &:before {
      left: 28px;
    }
  }

  &--small {
    width: 40px;
    height: 22px;

    &:before {
      width: 18px;
      height: 18px;
    }

    &.is-active {
      &:before {
        left: 18px;
      }
    }
  }
}
